import React, { FC } from 'react';
import { Title1 } from 'components/typography/Typography';
import { CardsProps, Colors } from '@symplbe/sympl-components';
import useCareerPageContext from 'hooks/context/career-page-context';
import TextAlignmentForm from '../shared/config-forms/TextAlignmentForm';
import ColorForm from '../shared/config-forms/ColorForm';
import 'twin.macro';

interface CardsConfigProps {
  sectionProps: CardsProps;
}

const CardsConfig: FC<CardsConfigProps> = ({ sectionProps }) => {
  const { handleUpdatePageSections } = useCareerPageContext();

  /**
   * The mainColor props currently holds the primary color
   * based on the "branding_color.primary_color".
   * The mainColor is only applied whenever an addSection Event is fired
   *
   * outside addSection Event, the mainColor is independent from the branding_color's config
   * since in v1 there is no way to update the branding_color's config
   */

  return (
    <div tw="overflow-y-auto">
      <Title1>Cards Config</Title1>
      <ColorForm
        colors={sectionProps.colors}
        textColors={sectionProps.textColors}
        bgColor={sectionProps.bgColor}
        enabledColors={['description_color']}
        update={(colors: Colors, bgColor) => {
          const filteredColors = Object.fromEntries(
            Object.entries(colors).filter(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ([_, value]) => value !== undefined && value !== null
            )
          );

          handleUpdatePageSections(sectionProps.sectionId as string, {
            bgColor,
            textColors: {
              ...sectionProps.textColors,
              ...filteredColors,
            },
          });
        }}
      />

      <TextAlignmentForm
        alignment={sectionProps.$textAlign}
        update={(val) =>
          handleUpdatePageSections(sectionProps.sectionId as string, {
            $textAlign: val,
          })
        }
      />
    </div>
  );
};

export default CardsConfig;
