import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import 'twin.macro';

import ColorPicker from 'components/form/ColorPicker';
import { Error } from 'components/typography/Typography';

interface InputColorPickerProps {
  name: string;
  defaultValue: string;
  rules: any;
  onChange?: (color: string) => void;
}

const InputColorPicker: React.FC<InputColorPickerProps> = ({
  name,
  rules,
  defaultValue,
  onChange: handleOnChange,
}) => {
  const { control, errors } = useFormContext();

  return (
    <>
      <Controller
        id={name}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ onChange, value }) => (
          <ColorPicker
            value={value}
            ariaInvalid={errors[name] !== undefined}
            onChange={(color) => {
              onChange(color);
              handleOnChange && handleOnChange(color);
            }}
            format="rgb"
          />
        )}
      />
      {errors[name] !== undefined && <Error>{rules.required?.message}</Error>}
    </>
  );
};

export default InputColorPicker;
