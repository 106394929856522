import React, { FC } from 'react';
import {
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
} from '@phosphor-icons/react';
import tw from 'twin.macro';

interface AlignmentFormProps {
  alignment: 'left' | 'center' | 'right' | undefined;
  update: (value: 'left' | 'center' | 'right') => void;
}

const TextAlignmentForm: FC<AlignmentFormProps> = ({ alignment, update }) => {
  return (
    <div tw="mt-4">
      <div tw="mt-2">Text alignment</div>
      <div tw="flex flex-row gap-1 mt-2">
        {[
          { value: 'left', icon: <TextAlignLeft size={28} /> },
          { value: 'center', icon: <TextAlignCenter size={28} /> },
          { value: 'right', icon: <TextAlignRight size={28} /> },
        ].map((option) => {
          const isActive = alignment === option.value;
          return (
            <button
              key={option.value}
              onClick={() =>
                update(option.value as 'left' | 'center' | 'right')
              }
              className="rounded-md bg-gray-100 p-2 transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
              css={[isActive && tw`bg-gray-200`]}
              aria-label={`Align text ${option.value}`}
              type="button"
            >
              {option.icon}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TextAlignmentForm;
