import { Colors, ISectionTextColors } from '@symplbe/sympl-components';
import InputColorPicker from 'components/form/input/InputColorPicker';
import React, { FC } from 'react';
import { DEFAULT_PRIMARY_COLOR } from 'utils/careers/section-helpers/sectionHelpers';
import 'twin.macro';

const ColorForm: FC<{
  colors: Colors;
  textColors: ISectionTextColors['textColors'];
  bgColor?: string;
  enabledColors?: (keyof Colors)[];
  update: (colors: Colors, bgColor: string) => void;
}> = ({
  colors,
  textColors,
  bgColor = 'transparent',
  enabledColors = [],
  update,
}) => {
  const isEnabled = (c: keyof Colors) =>
    c === 'primary_color'
      ? enabledColors.indexOf(c) >= 0
      : (textColors && c in textColors) || enabledColors.indexOf(c) >= 0;

  return (
    <div tw="w-full flex flex-col gap-y-4">
      {/* BACKGROUND COLOR */}
      <div>
        <div tw="mt-2">Background color</div>
        <InputColorPicker
          name="bgColor"
          defaultValue={bgColor}
          rules={{ required: true }}
          onChange={(value) => update(colors, value)}
        />
      </div>

      {/* PRIMARY COLOUR */}
      {isEnabled('primary_color') && (
        <div>
          <div tw="mt-2">Primary color</div>
          <InputColorPicker
            tw="z-[100]"
            name="primaryColor"
            defaultValue={colors.primary_color ?? DEFAULT_PRIMARY_COLOR}
            rules={{ required: true }}
            onChange={(value) =>
              update(
                {
                  ...colors,
                  primary_color: value,
                },
                bgColor
              )
            }
          />
        </div>
      )}

      {/* TITLE COLOUR */}
      {isEnabled('title_color') && (
        <div>
          <div tw="mt-2">Title color</div>
          <InputColorPicker
            name="titleColor"
            defaultValue={
              textColors!.title_color ?? colors.title_color ?? '#000000'
            }
            rules={{ required: true }}
            onChange={(value) =>
              update(
                {
                  ...colors,
                  title_color: value,
                },
                bgColor
              )
            }
          />
        </div>
      )}

      {/* SUBTITLE COLOUR */}
      {isEnabled('subtitle_color') && (
        <div>
          <div tw="mt-2">Subtitle color</div>
          <InputColorPicker
            name="subTitleColor"
            defaultValue={
              textColors!.subtitle_color ?? colors.subtitle_color ?? '#000000'
            }
            rules={{ required: true }}
            onChange={(value) =>
              update(
                {
                  ...colors,
                  subtitle_color: value,
                },
                bgColor
              )
            }
          />
        </div>
      )}

      {/* DESCRIPTION COLOUR */}
      {isEnabled('description_color') && (
        <div>
          <div tw="mt-2">Description color</div>
          <InputColorPicker
            name="descriptionColor"
            defaultValue={textColors!.description_color ?? '#000000'}
            rules={{ required: true }}
            onChange={(value) =>
              update(
                {
                  ...colors,
                  description_color: value,
                },
                bgColor
              )
            }
          />
        </div>
      )}

      {/* TEXT COLOR */}
      {isEnabled('text_color') && (
        <div>
          <div tw="mt-2">Text color</div>
          <InputColorPicker
            name="textColor"
            defaultValue={
              textColors!.text_color ?? colors.text_color ?? '#000000'
            }
            rules={{ required: true }}
            onChange={(value) =>
              update(
                {
                  ...colors,
                  text_color: value,
                },
                bgColor
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default ColorForm;
